import { BreadcrumbGroup } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConsoleRouteConfig } from "../common/routes";

export const FluxBreadcrumbs = () => {
  const [items, setItems] = useState([
    {
      text: ConsoleRouteConfig.home.pageName,
      href: `#${ConsoleRouteConfig.home.href}`,
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.demo.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.demo.pageName,
          href: `#${ConsoleRouteConfig.demo.href}`,
        },
      ]);
    }
  }, [location]);

  return <BreadcrumbGroup items={items} />;
};
