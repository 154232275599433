import * as React from "react";

import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import { CollectionWizard } from "../component/wizard/CollectionWizard";
import { SavedReportsTable } from "../component/table/SavedReportsTable";
import { PreviewBoard } from "../component/preview/PreviewBoard";
import { BaseReport } from "../common/types";
import { listReports } from "../api/savedReports";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import { GenerateMemo } from "../component/GenerateMemo";
import { ConfigurationTable } from "../component/table/ConfigurationTable";

export const Demo = () => {
  const [tableLoading, setTableLoading] = React.useState(false);
  const [baseReport, setBaseReport] = React.useState<BaseReport | null>(null);

  const [savedReports, setSavedReports] = React.useState<BaseReport[]>([]);

  React.useEffect(() => {
    setTableLoading(true);
    listReports("demo-user")
      .then((reports) => {
        console.log("Fetched saved reports", reports);
        setSavedReports(reports);
      })
      .catch((error) => {
        console.error("Failed to fetch saved reports", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, []);

  const refreshTable = () => {
    setTableLoading(true);
    listReports("demo-user")
      .then((reports) => {
        console.log("Fetched saved reports", reports);
        setSavedReports(reports);
      })
      .catch((error) => {
        console.error("Failed to fetch saved reports", error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Product Demo
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CollectionWizard
          onSubmit={() => {}}
          onSuccess={(baseReport: BaseReport) => {
            setBaseReport(baseReport);
            refreshTable();
          }}
          onCancel={() => {}}
        />

        <PreviewBoard
          baseReport={baseReport}
          onSave={(baseReport) => {
            setBaseReport(baseReport);
            refreshTable();
          }}
        />
        <SavedReportsTable
          savedReports={savedReports}
          onRefresh={refreshTable}
          onReportClick={(report) => {
            setBaseReport(report);
          }}
          loading={tableLoading}
        />
        <GenerateMemo />
      </SpaceBetween>
    </ContentLayout>
  );
};
