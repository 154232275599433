import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import FileUpload from "@cloudscape-design/components/file-upload";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";
import React from "react";
import { CsvDataType, GenerateMemoWorkflowStepType, MemoComponentSection, MemoOverallSection, Section } from "../common/types";
import { saveCsvResults } from "../api/savedReports";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import { StatusIndicatorProps, Steps } from "@cloudscape-design/components";
import { COMPONENT_SECTION_WORKFLOW, OVERALL_SECTION_WORKFLOW } from "../common/workflows";
import { createComponentSection, createOverallSection, generateMemo } from "../api/reportGeneration";

const defaultRunState: Record<GenerateMemoWorkflowStepType, StatusIndicatorProps.Type> = {
  [MemoComponentSection.BORROWER]: "pending",
  [MemoComponentSection.LOAN_TERMS]: "pending",
  [MemoComponentSection.SPONSOR_BACKGROUND]: "pending",
  [MemoComponentSection.RENT_ROLL_ANALYSIS]: "pending",
  [MemoComponentSection.HISTORICAL_FINANCIALS]: "pending",
  [MemoComponentSection.PRO_FORMA_ANALYSIS]: "pending",
  [MemoComponentSection.COLLATERAL_DESCRIPTION_AND_VALUATION]: "pending",
  [MemoComponentSection.GUARANTOR_BALANCE_SHEET_ANALYSIS]: "pending",
  [MemoComponentSection.GUARANTOR_INCOME_STATEMENT_ANALYSIS]: "pending",
  [MemoOverallSection.SUMMARY_AND_RECOMMENDATION]: "pending",
  [MemoOverallSection.STRENGTHS_AND_WEAKNESSES]: "pending",
  [MemoOverallSection.PROJECT_DESCRIPTION]: "pending",
  UPLOAD_DATA: "pending",
  FINALIZE_REPORT: "pending",
};

export const GenerateMemo = () => {
  const [reportId, setReportId] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [uploadedProForma, setUploadedProForma] = React.useState<File[]>([]);
  const [uploadedRentRoll, setUploadedRentRoll] = React.useState<File[]>([]);
  const [uploadedHistoricalFinancials, setUploadedHistoricalFinancials] = React.useState<File[]>([]);
  const [uploadedGuarantorBalanceSheet, setUploadedGuarantorBalanceSheet] = React.useState<File[]>([]);
  const [uploadedGuarantorCashFlow, setUploadedGuarantorCashFlow] = React.useState<File[]>([]);

  const [showStatusView, setShowStatusView] = React.useState(false);
  const [statusRecords, setStatusRecords] = React.useState<Record<GenerateMemoWorkflowStepType, StatusIndicatorProps.Type>>(defaultRunState);

  if (showStatusView) {
    return (
      <Container
        header={
          <Header
            actions={
              <Button
                variant="primary"
                onClick={() => {
                  setShowStatusView(false);
                  setStatusRecords({ ...defaultRunState });
                }}
              >
                Close
              </Button>
            }
          >
            Generate Memo
          </Header>
        }
      >
        <Steps
          steps={[
            {
              status: statusRecords["UPLOAD_DATA"],
              header: "Upload Data",
            },
            ...(COMPONENT_SECTION_WORKFLOW.map((step) => ({
              status: statusRecords[step.type],
              header: step.label,
            })) || []),
            ...(OVERALL_SECTION_WORKFLOW.map((step) => ({
              status: statusRecords[step.type],
              header: step.label,
            })) || []),
          ]}
        />
      </Container>
    );
  }

  const handleSubmit = async () => {
    setShowStatusView(true);
    const runState: Record<GenerateMemoWorkflowStepType, StatusIndicatorProps.Type> = { ...defaultRunState };
    const csvS3PathMap: Record<string, string> = {};
    runState["UPLOAD_DATA"] = "loading";
    try {
      csvS3PathMap[CsvDataType.PRO_FORMA] = await saveCsvResults("demo-user", uploadedProForma[0], CsvDataType.PRO_FORMA);
      csvS3PathMap[CsvDataType.RENT_ROLL] = await saveCsvResults("demo-user", uploadedRentRoll[0], CsvDataType.RENT_ROLL);
      csvS3PathMap[CsvDataType.HISTORICAL_FINANCIALS] = await saveCsvResults("demo-user", uploadedHistoricalFinancials[0], CsvDataType.HISTORICAL_FINANCIALS);
      csvS3PathMap[CsvDataType.GUARANTOR_BALANCE_SHEET] = await saveCsvResults("demo-user", uploadedGuarantorBalanceSheet[0], CsvDataType.GUARANTOR_BALANCE_SHEET);
      csvS3PathMap[CsvDataType.GUARANTOR_CASH_FLOW] = await saveCsvResults("demo-user", uploadedGuarantorCashFlow[0], CsvDataType.GUARANTOR_CASH_FLOW);
    } catch (e) {
      console.error("Failed to save CSV results", e);
      runState["UPLOAD_DATA"] = "error";
      setStatusRecords({ ...runState });
    }
    runState["UPLOAD_DATA"] = "success";
    setStatusRecords({ ...runState });

    const component_sections = [];

    for (const step of COMPONENT_SECTION_WORKFLOW) {
      try {
        runState[step.type] = "loading";
        setStatusRecords({ ...runState });
        const new_section = await createComponentSection(
          reportId!,
          "demo-user",
          step.type,
          (step.data || []).map((data) => ({ type: data.type as CsvDataType, s3Path: csvS3PathMap[data.type] }))
        );
        runState[step.type] = "success";
        setStatusRecords({ ...runState });
        component_sections.push(new_section);
      } catch (e) {
        console.error("Failed to create component section", e);
        runState[step.type] = "error";
        setStatusRecords({ ...runState });
      }
    }

    const overall_sections = OVERALL_SECTION_WORKFLOW.map((_) => ({ title: "", text: "", children: [] as Section[] }));
    for (const step of OVERALL_SECTION_WORKFLOW) {
      runState[step.type] = "loading";
      setStatusRecords({ ...runState });
      const new_section = await createOverallSection(step.type, component_sections);
      runState[step.type] = "success";
      setStatusRecords({ ...runState });
      overall_sections.push(new_section);
    }

    runState["FINALIZE_REPORT"] = "loading";
    setStatusRecords({ ...runState });
    const response = await generateMemo(title, [...component_sections, ...overall_sections]);
    if (response) {
      const { base64encoded, file_name } = response;
      const linkSource = `data:application/pdf;base64,${base64encoded}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = file_name;
      downloadLink.click();
    }
    runState["FINALIZE_REPORT"] = "success";
    setStatusRecords({ ...runState });
  };

  return (
    <Container header={<Header>Generate Memo</Header>}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="m">
            <FormField label="Report ID">
              <Input value={reportId || ""} onChange={(e) => setReportId(e.detail.value)} />
            </FormField>
            <FormField label="Title">
              <Input value={title || ""} onChange={(e) => setTitle(e.detail.value)} />
            </FormField>
            <ColumnLayout columns={3}>
              <FormField label="Pro Forma Results" description="Exported CSV from custom Excel Model">
                <FileUpload
                  onChange={(e) => setUploadedProForma(e.detail.value)}
                  value={uploadedProForma}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Upload file in CSV format"
                />
              </FormField>
              <FormField label="Historical Financials" description="Exported CSV from custom Excel Model">
                <FileUpload
                  onChange={(e) => setUploadedHistoricalFinancials(e.detail.value)}
                  value={uploadedHistoricalFinancials}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Upload file in CSV format"
                />
              </FormField>
              <FormField label="Rent Roll" description="Exported CSV from custom Excel Model">
                <FileUpload
                  onChange={(e) => setUploadedRentRoll(e.detail.value)}
                  value={uploadedRentRoll}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Upload file in CSV format"
                />
              </FormField>
              <FormField label="Guarantor Balance Sheet" description="Exported CSV from custom Excel Model">
                <FileUpload
                  onChange={(e) => setUploadedGuarantorBalanceSheet(e.detail.value)}
                  value={uploadedGuarantorBalanceSheet}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Upload file in CSV format"
                />
              </FormField>
              <FormField label="Guarantor Cash Flow" description="Exported CSV from custom Excel Model">
                <FileUpload
                  onChange={(e) => setUploadedGuarantorCashFlow(e.detail.value)}
                  value={uploadedGuarantorCashFlow}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Upload file in CSV format"
                />
              </FormField>
            </ColumnLayout>
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
};
