import { FunctionComponent } from "react";
import { ReportMetadata } from "../../common/types";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";

export const BasicInformation: FunctionComponent<{
  reportMetadata: ReportMetadata;
  onChange: (metadata: ReportMetadata) => void;
}> = ({ reportMetadata, onChange }) => {
  return (
    <Container>
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Report Name">
          <Input
            value={reportMetadata.reportName}
            placeholder="My Report"
            onChange={(event) =>
              onChange({
                ...reportMetadata,
                reportName: event.detail.value,
              })
            }
          />
        </FormField>
        <FormField label="Report Description">
          <Input
            value={reportMetadata.reportDescription}
            placeholder="Simple description"
            onChange={(event) =>
              onChange({
                ...reportMetadata,
                reportDescription: event.detail.value,
              })
            }
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
