import * as React from "react";
import Steps from "@cloudscape-design/components/steps";
import { StatusIndicatorProps } from "@cloudscape-design/components";

export interface StepInfo {
  label: string;
  description: string;
  value: number;
  additionalInfo?: string;
  status?: "success" | "error";
}

export const GenerationStatusView: React.FunctionComponent<{
  records: Record<GenerationSteps, StatusIndicatorProps.Type>;
}> = ({ records }) => {
  return (
    <Steps
      steps={[
        {
          status: records[GenerationSteps.INITIALIZING],
          header: "Report Initialization",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_TERM_SHEET],
          header: "Term Sheet",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_OPERATING_STATEMENT],
          header: "Operating Statement",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_APPRAISAL],
          header: "Appraisal",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_RENT_ROLL],
          header: "Rent Roll",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_PFS],
          header: "Personal Financial Statement",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_SPONSOR_BACKGROUND],
          header: "Sponsor Background",
        },
        {
          status: records[GenerationSteps.EXTRACT_FEATURES_BORROWER_BACKGROUND],
          header: "Borrower Background",
        },
      ]}
    />
  );
};

export enum GenerationSteps {
  INITIALIZING = "INITIALIZING",
  EXTRACT_FEATURES_TERM_SHEET = "EXTRACT_FEATURES_TERM_SHEET",
  EXTRACT_FEATURES_OPERATING_STATEMENT = "EXTRACT_FEATURES_OPERATING_STATEMENT",
  EXTRACT_FEATURES_APPRAISAL = "EXTRACT_FEATURES_APPRAISAL",
  EXTRACT_FEATURES_RENT_ROLL = "EXTRACT_FEATURES_RENT_ROLL",
  EXTRACT_FEATURES_PFS = "EXTRACT_FEATURES_PFS",
  EXTRACT_FEATURES_BORROWER_BACKGROUND = "EXTRACT_FEATURES_BORROWER_BACKGROUND",
  EXTRACT_FEATURES_SPONSOR_BACKGROUND = "EXTRACT_FEATURES_SPOSOR_BACKGROUND",
}
