import { ComponentSectionWorkflowStep, CsvDataType, MemoComponentSection, MemoOverallSection, OverallSectionWorkflowStep } from "./types";

export const COMPONENT_SECTION_WORKFLOW: ComponentSectionWorkflowStep[] = [
  {
    type: MemoComponentSection.LOAN_TERMS,
    label: "Loan Terms",
  },
  {
    type: MemoComponentSection.BORROWER,
    label: "Borrower",
  },
  {
    type: MemoComponentSection.SPONSOR_BACKGROUND,
    label: "Sponsor Background",
  },
  {
    type: MemoComponentSection.COLLATERAL_DESCRIPTION_AND_VALUATION,
    label: "Property Description",
  },
  {
    type: MemoComponentSection.RENT_ROLL_ANALYSIS,
    label: "Rent Roll Analysis",
    data: [
      {
        type: CsvDataType.RENT_ROLL,
      },
    ],
  },
  {
    type: MemoComponentSection.HISTORICAL_FINANCIALS,
    label: "Historical Financials",
    data: [
      {
        type: CsvDataType.HISTORICAL_FINANCIALS,
      },
    ],
  },
  {
    type: MemoComponentSection.PRO_FORMA_ANALYSIS,
    label: "Pro Forma Analysis",
    data: [
      {
        type: CsvDataType.PRO_FORMA,
      },
    ],
  },
  {
    type: MemoComponentSection.GUARANTOR_BALANCE_SHEET_ANALYSIS,
    label: "Guarantor Balance Sheet Analysis",
    data: [
      {
        type: CsvDataType.GUARANTOR_BALANCE_SHEET,
      },
    ],
  },
  {
    type: MemoComponentSection.GUARANTOR_INCOME_STATEMENT_ANALYSIS,
    label: "Guarantor Income Statement Analysis",
    data: [
      {
        type: CsvDataType.GUARANTOR_CASH_FLOW,
      },
    ],
  },
];

export const OVERALL_SECTION_WORKFLOW: OverallSectionWorkflowStep[] = [
  {
    type: MemoOverallSection.PROJECT_DESCRIPTION,
    label: "Project Description",
  },
  {
    type: MemoOverallSection.STRENGTHS_AND_WEAKNESSES,
    label: "Strengths and Weaknesses",
  },
  {
    type: MemoOverallSection.SUMMARY_AND_RECOMMENDATION,
    label: "Summary and Recommendation",
  },
];
