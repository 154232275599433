import { ContentLayout, Header, Link } from "@cloudscape-design/components";
import { HeroHeader } from "../component/HeroHeader";

export const Home = () => {
  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={<HeroHeader />}
    ></ContentLayout>
  );
};
