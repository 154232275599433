import { BaseReport, ReportMetadata } from "../common/types";
import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { Configuration } from "../common/types";

const SAVED_REPORTS_API_URL = EndpointConfigs.savedReportsApiUrl();

export const createReport = async (userId: string, reportMetadata: ReportMetadata) => {
  console.log(reportMetadata);
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
      reportMetadata,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create report");
    return null;
  }
  return response.data.reportId as string;
};

export const updateReport = async (baseReport: BaseReport) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      baseReport,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to update report");
  }
  return response.data.reportId as string;
};

export const saveCsvResults = async (userId: string, csvFile: File, csvDataType: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
      type: csvDataType,
      csv: await csvFile.text(),
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "SaveCsvResults",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to save CSV results");
    throw new Error("Failed to save CSV results");
  }
  return response.data.s3Path as string;
};

export const listReports = async (userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListReports",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list reports");
    return [];
  }
  return response.data.baseReports as BaseReport[];
};

export const deleteReport = async (reportId: string, userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      reportId,
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteReport",
      },
    }
  );
  if (response.status !== 200) {
    console.error(`Failed to delete report with ID: ${reportId}`);
    return false;
  }
  return true;
};

export const updateConfiguration = async (userId: string, configurationId: string, name: string, content: string) => {
  const configuration: Configuration = {
    configurationId,
    userId,
    name,
    content,
  }
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      configuration
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "UpdateConfiguration",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to update configuration");
    return null;
  }
  return response.data.configurationId as string;
};

export const listConfigurations = async (userId: string) => {
  const response = await axios.post(
    SAVED_REPORTS_API_URL,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListConfigurations",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list configurations");
    return [];
  }
  return response.data.configurations as Configuration[];
}