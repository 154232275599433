import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { CsvS3Path, Section } from "../common/types";

const REPORT_GENERATION_API_URL = EndpointConfigs.reportGenerationApiUrl();

export const generateMemo = async (title: string, sections: Section[]) => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {
      title: title,
      sections: sections,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "GenerateMemo",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to compose memo");
    throw new Error("Failed to compose memo");
  }
  const base64encoded = response.data.content as string;
  const file_name = response.data.metadata.file_name as string;
  return { base64encoded, file_name };
};

export const createComponentSection = async (reportId: string, userId: string, type: string, data: CsvS3Path[]) => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {
      reportId,
      userId,
      type,
      data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateComponentSection",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create component section");
    throw new Error("Failed to create component section");
  }
  return response.data as Section;
};

export const createOverallSection = async (type: string, sections: Section[]) => {
  const response = await axios.post(
    REPORT_GENERATION_API_URL,
    {
      type,
      sections,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateOverallSection",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to create overall section");
    throw new Error("Failed to create overall section");
  }
  return response.data as Section;
};
