import { FunctionComponent, useState } from "react";
import { Configuration } from "../../common/types";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";

export const ConfigurationTable: FunctionComponent<{
  configurations: Configuration[];
}> = ({ configurations }) => {
  const [selectedItems, setSelectedItems] = useState<Configuration[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="refresh" onClick={() => {}} />

              <Button disabled={selectedItems.length === 0} onClick={() => {}} loading={isDeleting}>
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Saved Configurations
        </Header>
      }
      selectedItems={selectedItems}
      items={configurations}
      columnDefinitions={[
        {
          id: "configurationId",
          header: "Configuration ID",
          cell: (item) => item.configurationId,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => <Link onClick={() => {}}>{item.name}</Link>,
        },
      ]}
    />
  );
};
