import { Button, CodeEditor, Container, ContentLayout, Form, FormField, Header, Input, Link, SpaceBetween } from "@cloudscape-design/components";
import { ConfigurationTable } from "../component/table/ConfigurationTable";
import { useEffect, useState } from "react";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/cloud_editor.css";
import "ace-builds/css/theme/cloud_editor_dark.css";
import { updateConfiguration, listConfigurations } from "../api/savedReports";
import { Configuration as ConfigurationType } from "../common/types";
import { log } from "console";

const USER = "demo-user";

export const Configuration = () => {
  const [value, setValue] = useState("{}");
  const [preferences, setPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [ace, setAce] = useState();
  const [name, setName] = useState("");
  const [configurations, setConfigurations] = useState<ConfigurationType[]>([]);

  const [configurationId, setConfigurationId] = useState("");

  const i18nStrings = {
    loadingState: "Loading code editor",
    errorState: "There was an error loading the code editor.",
    errorStateRecovery: "Retry",

    editorGroupAriaLabel: "Code editor",
    statusBarGroupAriaLabel: "Status bar",

    cursorPosition: (row: any, column: any) => `Ln ${row}, Col ${column}`,
    errorsTab: "Errors",
    warningsTab: "Warnings",
    preferencesButtonAriaLabel: "Preferences",

    paneCloseButtonAriaLabel: "Close",

    preferencesModalHeader: "Preferences",
    preferencesModalCancel: "Cancel",
    preferencesModalConfirm: "Confirm",
    preferencesModalWrapLines: "Wrap lines",
    preferencesModalTheme: "Theme",
    preferencesModalLightThemes: "Light themes",
    preferencesModalDarkThemes: "Dark themes",
  };

  useEffect(() => {
    async function loadAce() {
      const ace = await import("ace-builds");
      await import("ace-builds/webpack-resolver");
      ace.config.set("useStrictCSP", true);

      return ace;
    }

    async function fetchConfigurations() {
      const configs = await listConfigurations(USER);
      setConfigurations(configs);
    }

    Promise.all([loadAce(), fetchConfigurations()])
      .then(([ace]) => setAce(ace as any))
      .finally(() => {setLoading(false); console.log("Loaded configurations")});
  }, []);

  const handleUpdateConfiguration = async () => {
    try {
      setIsUpdating(true);
      const updatedConfigurationId = await updateConfiguration(USER, configurationId, name, value);
      if (updatedConfigurationId) {
        console.log("Configuration updated successfully:", updatedConfigurationId);
        // Optionally, refresh the configurations list or provide feedback to the user
        const configs = await listConfigurations(USER);
        setConfigurations(configs);
      }
    } catch (error) {
      console.error("Failed to update configuration:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Configuration
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <Container header={<Header description>Configuration Editor</Header>}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={() => {handleUpdateConfiguration()}} disabled={configurationId === "" && !isUpdating}>
                  Update
                </Button>
                <Button variant="primary" onClick={() => {handleUpdateConfiguration()}} disabled={!isUpdating}>
                  Create
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween size="l">
              <FormField label="Configuration ID">
                <Input value={configurationId} onChange={(event) => setConfigurationId(event.detail.value)} />
              </FormField>
              <FormField label="Name">
                <Input value={name} onChange={(event) => setName(event.detail.value)}/>
              </FormField>
              <FormField label="Content">
                <CodeEditor
                  ace={ace}
                  value={value}
                  language="json"
                  onDelayedChange={(event) => setValue(event.detail.value)}
                  preferences={preferences}
                  onPreferencesChange={(event) => setPreferences(event.detail)}
                  loading={loading}
                  i18nStrings={i18nStrings}
                  // should match the imports on top of this file
                  themes={{ light: ["cloud_editor"], dark: ["cloud_editor_dark"] }}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </Container>
        <ConfigurationTable configurations={configurations} />
      </SpaceBetween>
    </ContentLayout>
  );
};
